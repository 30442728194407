@tailwind base;

@tailwind components;

@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";

@import "styles/buttons.css";

@layer base {
  /* COMMON STYLES */
  #root {
    height: 100vh;
  }

  .wrapper {
    box-shadow: theme("boxShadow.frame");
  }
}

/* react-tostify overriding */
.Toastify__toast-container {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .Toastify__toast-container {
    width: 85vw;
    margin: 10px auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 768px) {
  .Toastify__toast-container {
    width: 90vw;
    max-width: 640px;
  }
}

@media only screen and (min-width: 1280px) {
  .Toastify__toast-container {
    right: 0;
    margin: 0;
    margin-right: 15px;
    position: fixed;
    width: 360px;
  }
}

.Toastify__toast {
  border-radius: 8px;
  border-left: 8px solid theme("colors.text.error");
}
