/* BUTTONS */
/* -----> Filled button*/
.primary-btn {
  color: theme("colors.btn.primary.label.default");
  background-color: theme("colors.btn.primary.fill.default");
  border: 3px solid theme("colors.btn.primary.border.default");
  box-shadow: theme("boxShadow.button");
}
.primary-btn:hover {
  color: theme("colors.btn.primary.label.hovered");
  background-color: theme("colors.btn.primary.fill.hovered");
  border: 3px solid theme("colors.btn.primary.border.hovered");
}
.primary-btn:focus {
  outline: none;
  color: theme("colors.btn.primary.label.focused");
  background-color: theme("colors.btn.primary.fill.focused");
  border: 3px solid theme("colors.btn.primary.border.focused");
}
.primary-btn:disabled {
  color: theme("colors.btn.primary.label.disabled");
  background-color: theme("colors.btn.primary.fill.disabled");
  border: 3px solid theme("colors.btn.primary.border.disabled");
}
/* -----> Text button*/
.text-btn {
  color: theme("colors.btn.text.label.default");
}
.text-btn:hover {
  color: theme("colors.btn.text.label.hovered");
}
.text-btn:focus {
  outline: none;
}
.text-btn:disabled {
  color: theme("colors.btn.text.label.disabled");
}
/* -----> Radio button*/
/* custom color for the checked state of radio inputs */
input[type="radio"] {
  border: 1px solid theme("colors.radio.border.default");
  cursor: pointer;
}
input[type="radio"]:hover {
  border: 1px solid theme("colors.radio.border.hovered");
  background-color: theme("colors.radio.fill.hovered");
}
input[type="radio"]:disabled {
  border: 1px solid theme("colors.radio.border.disabled");
  background-color: theme("colors.radio.fill.empty-disabled");
  cursor: not-allowed;
}
input[type="radio"]:focus {
  border: 1px solid theme("colors.radio.shape.selected");
  outline: none;
}
input[type="radio"]:checked::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: theme("colors.radio.shape.selected");
  position: absolute;
  top: 9px;
  left: 9px;
  transform: translate(-50%, -50%);
}

input[type="radio"]:checked:disabled::after {
  background-color: theme("colors.radio.shape.selected-disabled");
  cursor: not-allowed;
}
/* -----> Number input with $ sign before*/
.pseudo-number-input:focus-within {
  border: 1px solid theme("colors.border.focused");
  outline: none;
}
